@import "./variables.scss";
@import "./reset.scss";
@import "./font.scss";
@import "./mixins.scss";

body {
  @include text-body-regular;
}

div,
input,
form,
header,
nav,
main {
  box-sizing: border-box;
}

input {
  border: none;
  outline: none;
}

button {
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

#app {
  display: grid;
  grid-template-columns: max-content 1fr;
  position: relative;
}
