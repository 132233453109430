@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../public/fonts/GilroySemiBold/font.woff2") format("woff2"),
    url("../public/fonts/GilroySemiBold/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-BoldItalic";
  src: url("../public/fonts/GilroyBoldItalic/font.woff2") format("woff2"),
    url("../public/fonts/GilroyBoldItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url("../public/fonts/GilroyBold/font.woff2") format("woff2"),
    url("../public/fonts/GilroyBold/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: url("../public/fonts/GilroyMedium/font.woff2") format("woff2"),
    url("../public/fonts/GilroyMedium/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  src: url("../public/fonts/GilroySemiBoldItalic/font.woff2") format("woff2"),
    url("../public/fonts/GilroySemiBoldItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-MediumItalic";
  src: url("../public/fonts/GilroyMediumItalic/font.woff2") format("woff2"),
    url("../public/fonts/GilroyMediumItalic/font.woff") format("woff");
}
