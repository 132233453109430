:root {
  --secondary: 242, 201, 76;

  --primary-900: 8, 11, 57;
  --primary-800: 25, 30, 95;
  --primary-700: 27, 32, 109;
  --primary-600: 31, 36, 120;
  --primary-bg: 16, 20, 80;
  --secondary-bg: 242, 242, 242;

  --accent-active: 242, 201, 76;
  --accent-hover: 251, 217, 113;
  --accent-disabled: 251, 217, 113;

  --text-primary: 255, 255, 255;
  --text-secondary: 187, 186, 239;
  --text-disabled: 86, 96, 178;
  --text-grey: 189, 189, 189;

  --error-bg: 57, 8, 8;
  --error-border: 235, 87, 87;
  --error-font: 255, 172, 172;

  --gradient-1: linear-gradient(45deg, rgb(240, 189, 69), rgb(14, 7, 181));

  --shadow-50: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
  --shadow-100: 0 6px 16px 0 rgba(0, 0, 0, 0.24);
  --shadow-yellow: 0 4px 0 0 rgba(158, 128, 40, 1);

  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-4: 32px;
  --spacing-5: 48px;
  --spacing-6: 64px;
}
