@mixin text-h1 {
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 64px;
  line-height: 1.5em;
  letter-spacing: 4px;
  text-transform: uppercase;
}

@mixin text-h2 {
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 40px;
  line-height: 1.5em;
  letter-spacing: 2px;
}

@mixin text-h3 {
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 32px;
  line-height: 1.5em;
  letter-spacing: 2px;
}

@mixin text-h4 {
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 24px;
  line-height: 1.5em;
  letter-spacing: 2px;
}

@mixin text-large-body-bold {
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 18px;
  line-height: 1.3em;
  letter-spacing: 0px;
}

@mixin text-large-body-regular {
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 18px;
  line-height: 1.3em;
  letter-spacing: 0px;
}

@mixin text-body-bold {
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 16px;
  line-height: 1.3em;
  letter-spacing: 0px;
}

@mixin text-body-regular {
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
}

@mixin text-caption {
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 14px;
  line-height: 1.3em;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@mixin text-link {
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 16px;
  line-height: 1.3em;
  letter-spacing: 0px;
  text-decoration: underline;
}

@mixin text-list {
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  line-height: 1.3em;
  letter-spacing: 0px;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

@mixin button-medium {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  padding: 12px 16px;
  background-color: transparent;
  border: solid 2px rgba(var(--text-secondary), 1);
  color: rgba(var(--text-secondary), 1);
  span {
    @include text-large-body-bold;
    margin-left: 4px;
  }
  &:hover:not(:disabled) {
    color: white;
    border-color: white;
  }
  &:active:not(:disabled) {
    background-color: rgba(var(--text-disabled), 1);
    border-color: rgba(var(--text-disabled), 1);
    color: rgba(var(--primary-900), 1);
  }
  &:disabled {
    color: rgba(var(--text-disabled), 1);
    border-color: rgba(var(--text-disabled), 1);
    cursor: auto;
  }
}

@mixin button-large {
  @include text-large-body-bold;
  color: rgba(var(--primary-bg), 1);
  min-height: 64px;
  padding: 20px 40px;
  border-radius: 100px;
  background-color: rgba(var(--accent-active), 1);
  &:hover:not(:disabled) {
    background-color: rgba(var(--accent-hover, 1));
    box-shadow: var(--shadow-50);
  }
  &:active:not(:disabled) {
    box-shadow: 0 0 0 0 #000;
  }
  &:disabled,
  [data-disabled="true"] {
    cursor: auto;
    opacity: 0.4;
    pointer-events: none;
  }
}

@mixin button-text {
  @include text-body-bold;
  color: rgba(var(--text-secondary), 1);
  cursor: pointer;
  &:hover:not(:disabled) {
    color: white;
  }
  &:active:not(:disabled) {
    color: rgba(var(--text-disabled), 1);
  }
  &:disabled {
    color: rgba(var(--text-disabled), 1);
    cursor: auto;
  }
}
